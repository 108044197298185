.nae-map {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;

  & .nae-map-container {
    height: calc(100vh - 64px);
  }

  & .row, & .col {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.marker-cluster-custom {
  //background: rgba(15, 110, 143, 0.5);
  position: relative;

  & span {
    position: absolute;
    left: -20px;
    top: -20px;
    background: #FFFFFF;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  & > div {

  }
}

.map-marker {
  display: block;
  font-size: 12px;
  color: #585858;
  background: rgba(18, 128, 166, 0.7);
  border: 1px solid rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 4px;
  //text-shadow: 1px 0 rgba(18, 128, 166, 0.7);

  &.success {
    background-color: #39DA8A;
  }

  &.danger {
    background-color: #FF5B5C;
    color: #FFF;
  }

  &.warning {
    background-color: #FDAC41;
  }

  &.miss-signal {
    opacity: 0.6;
  }
}


.map-trucks-list {

  padding: 10px 0;

  z-index: 9999;

  & .inner {
    overflow: auto;

    height: calc(100vh - 104px);
  }

  & .title {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 3px;

    &:first-child {
      margin-top: 0;
    }
  }

  & .item {
    border-bottom: 1px solid #C7CFD6;
    padding: 5px 0;
    cursor: pointer;
    position: relative;

    & .address {
      overflow: hidden;
      font-size: 13px !important;
      height: 18px
    }

    & .subtitle {
      overflow: hidden;
      padding-right: 20px;
      height: 20px;
    }

    & .status {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 10px;
      position: absolute;
      top: 7px;
      right: 5px;

      &.success {
        background-color: #39DA8A;
      }

      &.danger {
        background-color: #FF5B5C;
        color: #FFF;
      }

      &.warning {
        background-color: #FDAC41;
      }

      &.miss-signal {
        opacity: 0.6;
      }
    }
  }

}


.btn-group-vertical button {
  display: block;
  width: 100%;
  margin: 5px 0 !important;

  &:first-child {
    margin-top: 0 !important;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.popup-modal-close {
  background: transparent;
  border: none;
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
}

.tyres-modal {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);

  & .ReactModal__Content {
    width: 500px !important;
    min-width: 90vw !important;
    max-width: 90vw !important;
  }
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  padding: 0 !important;
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;

  border: none !important;
  border-radius: 0 !important;
  inset: 0 !important;
  transform: none;
  background: none !important;
position: initial !important;

  & > .container {
    position: relative !important;
  }

  & .card {
    max-height: 80vh;
    overflow: auto;
  }
}

.tyre {
  //display: inline;
  //width: 300px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 4px;
  //border: 1px solid #C7CFD6;
  -webkit-box-shadow: 0px 0px 11px 2px #C7CFD6;
  box-shadow: 0px 0px 11px 2px #C7CFD6;

  & .form-group {
    display: flex;
    margin: 0 !important;

    & > label {
      width: 120px;
      font-size: 10px;
      padding: 0 10px;
      margin-top: 6px;
    }

    & > input {
      width: 120px !important;
      margin: 0 !important;
      border: none;
      border-bottom: 1px solid #C7CFD6;
      border-radius: 0;
    }
  }

  &.tyre-left label {
    text-align: right;
  }

  & .index {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C7CFD6;
    background: #C7CFD6;
    color: #FFF;

    position: absolute;
    top: -20px;
    width: 30px;
    height: 30px;
    border-radius: 15px;

    &-right {
      right: -20px;
    }

    &-left {
      left: -20px;
    }
  }

  &.tyre-10 {
    & .index {
      right: 40px;
    }
  }

  &.tyre-1 {
    & .index {
      left: 40px;
    }
  }
}

.reports {
  td {
    border-right: 1px solid #FFF;
    text-align: center;

    &.link {
      font-weight: 500;
      color: #5a8dee !important;
      cursor: pointer;
    }
  }

  th {
    text-align: center !important;
  }

  & .inactive {
    background-color: #C7CFD6;
    text-align: center;
  }

  & .success {
    background-color: #39DA8A;
  }

  & .warning {
    background-color: #FDAC41;
  }

  & .error {
    background-color: #FF5B5C;
  }
}

input[readonly] {
  background-color: #C7CFD6;
}

.text-sm {
  font-size: 10px !important;
}
